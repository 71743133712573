// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(medium);
}
//
.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(medium);
}

// Site title
.site-desktop-title a {
  font-weight: bold;
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}
.header-container {

  .header {

    .block-container {

      .block {
        height: .15rem;
      }
    }
    .header-content-container {
      height: 5.5rem;
      padding: 0px;

      .logo-container {
        padding: .5rem 3rem;

        .white-logo {
          margin-top: .5rem;
          width: 15rem;
        }
        p {
          color: $white;
          font-size: .75rem;
          padding-left: 1rem;
          max-width: 15rem;
          text-align: left;
          border-left: 1px solid $white;
          margin: 0px;
        }
      }
    }
    .call-now-container {
      top: 1rem;
      right: 2.5rem;
      border-radius: $global-radius;
      overflow: hidden;

      a {
        padding: .25rem 2rem;

        h1 {
          font-size: 1rem;
        }
      }
    }
  }
}
