@mixin crystallize() {
    border: 1px solid transparentize($white, 0.9);
}
@mixin blurred($class,$color,$opacity) {
    @supports (-webkit-backdrop-filter: saturate(180%) blur(20px)) or (backdrop-filter: saturate(180%) blur(20px)) {
        #{$class} {
            background: transparentize($color, $opacity) !important;
            -webkit-backdrop-filter: saturate(180%) blur(20px);
            backdrop-filter: saturate(180%) blur(20px);
        }
    }
}
@mixin gradiantbg($top,$bottom) {
    background: $bottom;
    background: -moz-linear-gradient(350deg, $bottom 20%, $top 100%);
    background: -webkit-linear-gradient(350deg, $bottom 20%, $top 100%);
    background: linear-gradient(350deg, $bottom 20%, $top 100%);
}
@mixin textwrap() {
    padding-right: 30px;
    padding-left: 30px;
}
@mixin standardrow() {
    padding-top: 7.5625rem;
    padding-bottom: rem-calc(20);

    @media screen and (max-width: 39.9375em) {
        padding-top: 3.5rem;
        padding-bottom: rem-calc(20);
    }
}
@mixin standardreveal($height) {
    top: 0px !important;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: absolute;
    margin: auto;
    height: $height;
    border: none;

}
@mixin standardblock() {
    padding-bottom: rem-calc(50);
}
@mixin crystallize() {
    border: 1px solid transparentize($white, 0.9);
}
@mixin borderboxshadow() {
    -webkit-box-shadow: 1px 2px 12px 4px rgba(0,0,0,0.1);
    -moz-box-shadow: 1px 2px 12px 4px rgba(0,0,0,0.1);
    box-shadow: 1px 2px 12px 4px rgba(0,0,0,0.1);
}
@mixin borderboxshadowreset() {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}