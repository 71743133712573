$dark-nav-color: #2C3840;
$teal: #51b9a3;
$orange: #f46524;
$red: #f46524;
$green: #99c625;
$blue: #005ba0;
$man-drake-blue: #8488a0;
$old-mill-blue: #6f7182;
$blue-plaza: #282a3d;
$hormagaunt-purple: #54576b;
$pure-white: #ffffff;
$harvest-gold: #ffb401;
$russian-black: #00041e;
$black: $russian-black;
$off-white: #f5f5f7;
$bright-gray: #edebf2;
$briquette-gray: #515151;
$regent-gray: #74848c;
$sonic-silver: #767676;
$transparent: transparent;
$dm-light-gray: $man-drake-blue;
$dm-medium-gray: $old-mill-blue;
$dm-dark-gray: $hormagaunt-purple;
$dm-background: $blue-plaza;