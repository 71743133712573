.lead-generation-page-10 {
  background: $off-white;
  height: 100%;

  .top-section {
    position: relative;
    padding-top: 4rem;

    .top-background {
      background: radial-gradient(76.99% 708.33% at 76.25% 52%,
      rgba(241, 241, 255, 0.59) 0%,
      rgba(188, 203, 233, 0.59) 100%);
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      max-height: 25rem;
      width: 100%;
    }
    .top-grid-container {
      padding: 0px;
      position: relative;
      z-index: 2;

      .top-image-container {
        padding: 0rem 4rem;
        margin: 0 auto;
        text-align: center;

        img {
          width: 21rem;
          border-radius: 1rem;

          @include borderboxshadow();
        }
      }

      .top-background-starburst-container {
        position: relative;
        height: 10rem;
        width: 10rem;
        margin: 0px;

        p {
          text-align: center;
          position: relative;
          top: 2rem;
          left: 0px;
          font-size: 1rem;
          text-transform: uppercase;
          color: $primary-color;
          font-weight: bolder;
          z-index: 3;
        }

        .top-background-starburst {
          position: absolute;
          top: 0px;
          z-index: 2;
          height: 100%;
          width: 100%;
          left: 0%;
          transform: rotate(-45deg);
          font-size: 2rem;

          span {
            font-size: 3rem;
          }
        }
      }
      h1 {
        color: $primary-color;
        font-weight: bolder;
        font-size: 2rem;
      }
    }
    .grid-container {
      padding-top: 0px;
    }
    .top-section-form-container {
      margin-top: 4rem;
      padding: 2rem 4rem;
      background: $primary-color;
      position: relative;
      z-index: 2;
      border-radius: $global-radius;

      @include borderboxshadow();

      h3 {
        font-size: 2.5rem;
        color: $white;
      }
      p {
        margin: 2rem auto 1rem;
        color: $white;
      }

      .top-section-call-form {
        border-right: 1px solid $light-gray;

        a {
          margin-top: .75rem;
          font-size: 1.5rem;
          padding: .5rem 3rem;

          span {
            padding-left: 1rem;
          }
        }
      }
      .top-section-zip-form-container {

        .top-section-zip-form {
          padding: 0px 0px 0px 4rem;
          text-align: center;
          margin-top: 2rem;

          .top-section-zip-form-input {
            background: $white;
            border-radius: 0px;
            border: none;
            margin: 0px;
            text-indent: 1rem;
            border-top: 1px solid $light-gray;
            border-left: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            border-bottom-left-radius: $global-radius;
            border-top-left-radius: $global-radius;
            text-align: center;
            font-size: 1.5rem;
            font-weight: bolder;
          }
          .top-section-zip-form-button {
            background: $alert-color;
            border-radius: 0px;
            color: $white;
            display: block;
            height: 100%;
            margin: 0px;
            padding: 0px 1rem;
            border-bottom-right-radius: $global-radius;
            border-top-right-radius: $global-radius;
          }
        }
      }
    }
    .top-section-logos-container {
      margin-top: 1rem;
      padding: 0px 8rem;
    }
  }
  .middle-section {
    margin: 2rem auto;

    h3 {
      color: $primary-color;
      font-weight: bolder;
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
      font-weight: normal;
      color: $primary-color;
    }
    .middle-blocks-container {
      margin-top: 4rem;

      .middle-block {
        background: $white;
        position: relative;
        z-index: 2;
        border-radius: $global-radius;
        overflow: hidden;
        height: 100%;

        @include borderboxshadow();

        h4 {
          font-size: 1.25rem;
          font-weight: normal;
          font-weight: bold;
          padding: 1rem 2rem;
        }
        .image-container {
          background: $primary-color;
          padding: 2rem 4rem;

          @include crystallize();

          img {
            width: 4rem;
            height: 4rem;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .bottom-section {
    margin-top: 6rem;
    background: $primary-color;
    padding: 0px;

    h5 {
      color: $white;
      font-size: 2.5rem;
      font-weight: bold;
    }
    p {
      font-size: 1rem;
      font-weight: normal;
      color: $white;
      padding-right: 4rem;
    }
    .bottom-blocks-container {

      .bottom-block {
        margin-top: 2rem;
        position: relative;

        .separator-center {
          margin-top: 1rem;
          font-size: 2rem;
          font-weight: bolder;
          color: $white;

          &::after {
            width: 5rem;
            margin-top: .5rem;
          }
        }

        p {
          padding-right: 0px;
          color: $white;
          font-weight: bolder;
          font-size: 1rem;
        }

        .image-container {


          .image {
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 12.5rem;
            border-radius: $global-radius;
          }
        }
      }
    }
    .bottom-cta-section {
      background: $off-white;
      padding: 8rem 4rem;
      margin-top: 4rem;

      .bottom-form-container {
        background: $light-gray;
        border-radius: $global-radius;
        padding: 2rem 4rem;
        max-width: 50rem;
        margin: 0 auto;

        h6 {
          font-size: 2rem;
          font-weight: bolder;
          color: $primary-color;
        }

        span {
          color: $primary-color;
        }

        .bottom-section-zip-form {
          padding: 0px;
          text-align: center;
          margin-top: 2rem;

          .bottom-section-zip-form-input {
            background: $white;
            border-radius: 0px;
            border: none;
            margin: 0px;
            text-indent: 1rem;
            border-top: 1px solid $light-gray;
            border-left: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            border-bottom-left-radius: $global-radius;
            border-top-left-radius: $global-radius;
          }
          .bottom-section-zip-form-button {
            background: $secondary-color;
            border-radius: 0px;
            color: $white;
            display: block;
            height: 100%;
            margin: 0px;
            padding: 0px 1rem;
            border-bottom-right-radius: $global-radius;
            border-top-right-radius: $global-radius;
          }
        }
      }
    }
    .bottom-disclaimer-text {
      background: lighten($secondary-color, 45%);

      .grid-container {
        padding: 6rem 0px;

        p {
          font-size: .65rem;
          color: $dark-gray !important;
          margin: 0px;
        }
      }
    }
  }
}
