.lead-generation-page-11 {
  background: $off-white;
  height: 100%;

  .top-section {
    position: relative;
    padding-top: 4rem;

    .top-background {
      background: url('https://res.cloudinary.com/medicare-insurance/image/upload/w_1080,f_auto/teal-green-curve-background_53876-113110_ptzskx.jpg');
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      max-height: 25rem;
      width: 100%;
    }
    .top-grid-container {
      padding: 0px;
      position: relative;
      z-index: 2;

      .top-image-container {
        padding: 0rem 4rem;
        margin: 0 auto;
        text-align: center;

        img {
          width: 21rem;
          border-radius: 1rem;

          @include borderboxshadow();
        }
      }

      .top-background-starburst-container {
        position: relative;
        height: 10rem;
        width: 10rem;
        margin: 0px;

        p {
          text-align: center;
          position: relative;
          top: 2rem;
          left: 0px;
          font-size: 1rem;
          text-transform: uppercase;
          color: $primary-color;
          font-weight: bolder;
          z-index: 3;
        }

        .top-background-starburst {
          position: absolute;
          top: 0px;
          z-index: 2;
          height: 100%;
          width: 100%;
          left: 0%;
          transform: rotate(-45deg);
          font-size: 2rem;

          span {
            font-size: 3rem;
          }
        }
      }
      h1 {
        color: $primary-color;
        font-weight: bolder;
        font-size: 2rem;
      }
    }
    .grid-container {
      padding-top: 0px;
    }
    .top-section-form-container {
      margin-top: 4rem;
      padding: 2rem 4rem;
      background: $white;
      position: relative;
      z-index: 2;
      border-radius: $global-radius;

      @include borderboxshadow();

      .top-section-multi-step-confirm {
        margin-top: 2rem;

        h3 {
          font-size: 2rem;
          color: $primary-color;
        }
        p {
          margin: 1rem auto 1rem;
          color: $primary-color;
        }

        .top-section-multi-step-confirm-button {
          margin-top: .75rem;
          margin-bottom: 2rem;
          font-size: 1.5rem;
          padding: 1.5rem 5rem;
          background: $alert-color;
          color: $white;

          span {
            padding-left: 1rem;
          }
        }
      }
      .top-section-multi-step-form {

        #field_8_2 {
          width: 100%;
        }
        .gform_page_footer {

          .button {
            margin-bottom: 1rem;
          }
        }
      }
    }
    .top-section-logos-container {
      margin-top: 1rem;
      padding: 0px 8rem;
    }
  }
  .gform_body {

    .gform_wrapper {

      .gfield_radio {

        .li {
          margin-bottom: 1.5rem;

          input {
            margin-top: .5rem;
          }
        }
      }
    }
  }
  .middle-section {
    margin: 2rem auto;

    h3 {
      color: $primary-color;
      font-weight: bolder;
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
      font-weight: normal;
      color: $primary-color;
    }
    .middle-blocks-container {
      margin-top: 4rem;

      .middle-block {
        background: $white;
        position: relative;
        z-index: 2;
        border-radius: $global-radius;
        overflow: hidden;
        height: 100%;

        @include borderboxshadow();

        h4 {
          font-size: .9rem;
          font-weight: normal;
          font-weight: bold;
          padding: 1rem 2rem;
        }
        .image-container {
          background: $primary-color;
          background: rgb(15,56,90);
          background: linear-gradient(90deg,
          transparentize($primary-color, 0),
          transparentize($primary-color, .15),
          transparentize($primary-color, 0));
          padding: 2rem 4rem;

          @include crystallize();

          img {
            width: 4rem;
            height: 4rem;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .bottom-section {
    margin-top: 6rem;
    background: $primary-color;
    background: rgb(15,56,90);
    background: linear-gradient(90deg,
    transparentize($primary-color, 0),
    transparentize($primary-color, .15),
    transparentize($primary-color, 0));
    padding: 0px;

    h5 {
      color: $white;
      font-size: 2.5rem;
      font-weight: bold;
    }
    p {
      font-size: 1rem;
      font-weight: normal;
      color: $white;
      padding-right: 4rem;
    }
    .bottom-cta-section {
      background: $off-white;
      padding: 8rem 4rem;
      margin-top: 4rem;

      .bottom-form-container {
        background: $light-gray;
        border-radius: $global-radius;
        padding: 2rem 4rem;
        max-width: 50rem;
        margin: 0 auto;

        h6 {
          font-size: 2rem;
          font-weight: bolder;
          color: $primary-color;
        }

        span {
          color: $primary-color;
        }

        .bottom-section-zip-form {
          padding: 0px;
          text-align: center;
          margin-top: 2rem;

          .bottom-section-zip-form-input {
            background: $white;
            border-radius: 0px;
            border: none;
            margin: 0px;
            text-indent: 1rem;
            border-top: 1px solid $light-gray;
            border-left: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
            border-bottom-left-radius: $global-radius;
            border-top-left-radius: $global-radius;
          }
          .bottom-section-zip-form-button {
            background: $secondary-color;
            border-radius: 0px;
            color: $white;
            display: block;
            height: 100%;
            margin: 0px;
            padding: 0px 1rem;
            border-bottom-right-radius: $global-radius;
            border-top-right-radius: $global-radius;
          }
        }
      }
    }
    .bottom-disclaimer-text {
      background: lighten($secondary-color, 45%);

      .grid-container {
        padding: 6rem 0px;

        p {
          font-size: .65rem;
          color: $dark-gray !important;
          margin: 0px;
        }
      }
    }
  }
}
