@include breakpoint(small only) {
  .header-container {

    .header {

      .call-now-container {
        display: none;
      }
      .header-content-container {
        height: 5rem !important;

        .logo-container {

          .logo {
            height: 1rem;
            width: 10rem !important;
            margin: 0 auto;
          }
        }
        p {
          display: none;
        }
      }
    }
  }
  .lead-generation-page-10 {

    .top-section {
      padding: 4rem 2rem 2rem !important;

      .top-image-container {
        display: none;
      }
      .grid-container {
        padding: 0px;
      }
      .top-background-starburst-container {
        margin-left: 3rem !important;
        height: 8rem !important;
        width: 8rem !important;
        left: 15% !important;

        p {
          top: 1rem !important;
        }
        span {
          font-size: 2.5rem !important;
        }
      }
      h1 {
        text-align: center;
        margin-top: 1.5rem;
      }
      p {
        text-align: center;
        margin: 0px;
      }
      .top-section-form-container {
        padding: 2rem !important;

        h3 {
          font-size: 1.75rem !important;
        }
        p {
          margin: 1rem auto 2rem !important;
        }
        .top-section-call-form  {
          border: none !important;
          padding-bottom: 2rem;
          border-bottom: 1px solid $light-gray !important;

          a {
            font-size: 1rem !important;
          }
        }
        .top-section-zip-form-container {
          padding-top: 2rem;

          .top-section-zip-form {
            padding: 0px !important;
          }
        }
      }
      .top-section-logos-container {
        padding: 0px !important;
      }
    }
    .middle-section {
      margin: 0px !important;

      .grid-container {
        padding-top: 0px;

        h3 {
          font-size: 1.75rem !important;
        }
        p {
          font-size: 1rem !important;
        }
        .middle-blocks-container {

          .middle-block {
            height: auto !important;
            margin-bottom: 2rem;
          }
        }
      }
    }
    .bottom-section {
      p {
        padding: 0px !important;
      }
      .bottom-cta-section {
        padding: 0px !important;

        h6 {
          font-size: 1.5rem !important;
        }
        p {
          font-size: 1rem !important;
        }
      }
      .bottom-disclaimer-text {
        padding: 0px 2rem !important;

        .grid-container {
          padding: 2rem 0px !important;
        }
      }
    }
  }
}
